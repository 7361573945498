@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --checkbox-color: #ee9ca7;
  --checkbox-shadow: rgba(238, 156, 167, 0.2);
  --add-button: #ee9ca7;
  --add-button-shadow: rgba(238, 156, 167, 0.4);
  /*===== Colores =====*/
  --first-color: #9D4BFF;
  --first-color-dark: #23004D;
  --first-color-light: #A49EAC;
  --first-color-lighten: #F2F2F2;

  /*===== Font and typography =====*/
  --body-font: 'Open Sans', sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
}

@media screen and (min-width: 768px) {
  :root {
    --checkbox-color: #ee9ca7;
    --checkbox-shadow: rgba(238, 156, 167, 0.2);
    --add-button: #ee9ca7;
    --add-button-shadow: rgba(238, 156, 167, 0.4);
    --first-color: #9D4BFF;
    --first-color-dark: #23004D;
    --first-color-light: #A49EAC;
    --first-color-lighten: #F2F2F2;
    --body-font: 'Open Sans', sans-serif;
    --h1-font-size: 1.5rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
  }
}

.AppBody {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(62deg, rgba(1, 95, 183, 0.9732216701) 13%, rgba(255, 122, 151, 0.5) 4%), linear-gradient(44deg, rgba(0, 43, 99, 0.0792209024) 39%, rgba(242, 140, 143, 0.5) 18%), linear-gradient(118deg, rgba(84, 202, 242, 0.0315299727) 40%, rgba(247, 155, 187, 0.5) 54%), linear-gradient(58deg, rgba(90, 90, 237, 0.1614444357) 83%, rgba(249, 156, 142, 0.5) 23%);
  background-blend-mode: normal, lighten, multiply, hard-light;
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
}

input {
  outline: none;
}

ul {
  list-style: none;
  padding: 0;
}

.app-container {
  border-radius: 26px;
  width: 90vw;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  /* height: 90vh; */
  /* max-width: 600px; */
  max-height: 90vh;
  background-color: #10101d;
  padding: 26px;
  overflow: auto;
}

.app-header {
  font-size: 26px;
  line-height: 32px;
  margin: 0 0 12px 0;
  color: #fff;
  margin-top: 26px;
}

.submit-task {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border: none;
  background: var(--add-button);
  color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus'%3E%3Cline x1='12' y1='5' x2='12' y2='19'/%3E%3Cline x1='5' y1='12' x2='19' y2='12'/%3E%3C/svg%3E");
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 12px 0 var(--add-button-shadow);
}

.add-task {
  height: 44px;
  font-size: 26px;
  display: flex;
}

.task-input {
  width: 100%;
  padding: 0 4px;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #fff; */
  background-color: transparent;
  margin-right: 12px;
  color: #fff;
  box-shadow: none;
  border-radius: 44px;
  font-size: 20px;
  font-weight: 900;
}

.task-input:placeholder {
  color: #fff;
}

.task-list-item {
  background-color: #191933;
  border-radius: 26px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  padding: 8px;
  margin-top: 12px;
}

.task-list-item input {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check' stroke='%23fff'%3E%3Cpolyline points='20 6 9 17 4 12'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0;
  transition: 0.2s;
  margin-right: 8px;
  flex-shrink: 0;
  margin-top: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.task-list-item input:hover {
  border-color: var(--checkbox-color);
  box-shadow: 0 0 0 3px var(--checkbox-shadow);
}

.task-list-item input:checked {
  background-size: 10px;
  border: 1px solid var(--checkbox-color);
  background-color: var(--checkbox-color);
}

.task-list-item input:checked+span {
  color: rgba(255, 255, 255, 0.5);
  -webkit-text-decoration: line-through rgba(255, 255, 255, 0.8);
  text-decoration: line-through rgba(255, 255, 255, 0.8);
}

.task-list-item-label {
  display: flex;
  align-items: flex-start;
  color: #fff;
  margin-right: 8px;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  width: 70vw;
}

.task-list-item-empty {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
  color: #000;
  padding: 26px;
  border-radius: 44px;
  background: rgba(255, 255, 255, 0.5);
  /* margin-right: 112730373; */
  font-size: 26px;
  font-weight: 900;
  line-height: 24px;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  width: 100%;
}

.delete-btn {
  margin-left: auto;
  margin-right: 12px;
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ff3d46' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-trash-2'%3E%3Cpolyline points='3 6 5 6 21 6'/%3E%3Cpath d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'/%3E%3Cline x1='10' y1='11' x2='10' y2='17'/%3E%3Cline x1='14' y1='11' x2='14' y2='17'/%3E%3C/svg%3E");
  background-size: 16px;
  background-position: center;
  cursor: pointer;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {

  input[type=checkbox],
  input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 44px;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: rgb(255 253 253 / 57%);
  border-radius: 50px;
}

#FormBody {
  background-color: var(--first-color-lighten);
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--first-color-dark);
}

#FormBody h1 {
  margin: 0;
}

#FormBody a {
  text-decoration: none;
}

#FormBody img {
  max-width: 100%;
  height: auto;
  display: block;
}

.login {
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.login__content {
  display: grid;
}

.login__img {
  justify-self: center;
}

.login__img img {
  width: 310px;
  margin-top: 1.5rem;
}

.login__forms {
  position: relative;
  height: 368px;
}

.loginform_reg {
  position: relative;
  height: 520px;
}

.login__registre,
.login__create {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  background-color: var(--first-color-lighten);
  padding: 2rem 1rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 8px 20px rgba(35, 0, 77, .2);
  animation-duration: .4s;
  animation-name: animate-login;
}

@keyframes animate-login {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.login__title {
  font-size: var(--h1-font-size);
  margin-bottom: 2rem;
}

.login__box {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: .5rem;
  padding: 1.125rem 1rem;
  background-color: #FFF;
  margin-top: 1rem;
  border-radius: .5rem;
}

.login__box_pfp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* grid-template-columns: max-content 1fr;
  column-gap: .5rem; */
  padding: 1.125rem 1rem;
  background-color: #FFF;
  margin-top: 1rem;
  border-radius: .5rem;
}

.login__box_pfp_Submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.125rem 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
  border-radius: 20px;
  color: #fff;
  /* cursor: pointer; */
}

.login__icon {
  font-size: 1.5rem;
  color: var(--first-color);
}

.login__input {
  border: none;
  outline: none;
  font-size: var(--normal-font-size);
  font-weight: 700;
  color: var(--first-color-dark);
}

.login__input_submit {
  border: none;
  outline: none;
  font-size: var(--normal-font-size);
  font-weight: 700;
}

.login__input::placeholder {
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
  color: var(--first-color-light);
}

.login__forgot {
  display: block;
  width: max-content;
  margin-left: auto;
  margin-top: .5rem;
  font-size: var(--small-font-size);
  font-weight: 600;
  color: var(--first-color-light);
}

.login__button {
  display: block;
  padding: 1rem;
  margin: 2rem 0;
  background-color: var(--first-color);
  color: #FFF;
  font-weight: 600;
  text-align: center;
  border-radius: .5rem;
  transition: .3s;
}

.login__button:hover {
  background-color: var(--first-color-dark);
}

.login__account,
.login__signin,
.login__signup {
  font-weight: 600;
  font-size: var(--small-font-size);
}

.login__account {
  color: var(--first-color-dark);
}

.login__signin,
.login__signup {
  color: var(--first-color);
  cursor: pointer;
}

.login__social {
  margin-top: 2rem;
}

.login__social-icon {
  font-size: 1.5rem;
  color: var(--first-color-dark);
  margin: 0 1.5rem;
}

/*Show login*/
.block {
  display: block;
}

/*Hidden login*/
.none {
  display: none;
}

/* ===== MEDIA QUERIES =====*/
@media screen and (min-width: 576px) {
  .login__forms {
    width: 348px;
    justify-self: center;
  }
}

@media screen and (min-width: 1024px) {
  .login {
    height: 100vh;
    overflow: hidden;
  }

  .login__content {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    align-items: center;
    margin-left: 10rem;
  }

  .login__img {
    display: flex;
    width: 600px;
    height: 588px;
    background-color: var(--first-color-lighten);
    border-radius: 1rem;
    padding-left: 1rem;
  }

  .login__img img {
    width: 390px;
    margin-top: 0;
  }

  .login__registre,
  .login__create {
    left: -11rem;
  }

  .login__registre {
    bottom: -2rem;
  }

  .login__create {
    bottom: -5.5rem;
  }
}

button {
  cursor: pointer;
}

.ProfileDetails{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.ProfileDetails button {
  color: #000;
  font-weight: 900;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(26px);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  bottom: none;
  outline: none;
}

.ProfileDetails h1 {
  color: #fff;
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
  bottom: none;
  outline: none;
  margin-right: 10px;
}
.ChangePassword {
  background: url("https://t3.ftcdn.net/jpg/04/76/66/96/360_F_476669635_6P4H8yAayrbWwhHaJiwdntr5If6QNp2P.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.ChangePassword .ChangePasswordContainer {
  background-color: rgb(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  height: 90vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  border-radius: 44px;
  flex-direction: column;
}

.ChangePassword .ChangePasswordContainer h1 {
  color: #ffbc00;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 2rem;
}

.ChangePassword .ChangePasswordContainer form {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.ChangePassword .ChangePasswordContainer form input {
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 17px;
  padding-bottom: 17px;
  outline: none;
  border: 1px solid #a7bcff;
  color: #ff0058;
  border-radius: 80px;
  font-weight: 800;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  text-align: center;
  display: inline;
  width: 100%;

}

.ChangePassword .ChangePasswordContainer form input::placeholder {
  color: #ff0058;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.ChangePassword .ChangePasswordContainer button {
  background-color: #a7bcff;
  color: white;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  transition: 0.6s;
  margin: 26px;
}